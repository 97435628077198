<div class="podcast-detail-container" *ngIf="podcast$ | async as podcast">
  <div class="podcast-content">
    <div class="video-section">
      <app-video-player [media]="podcast"></app-video-player>
    </div>
    <div class="playlist-section" *ngIf="episodes$ | async as episodes">
      <div class="podcast-header">
        <h1 class="podcast-title">
          {{ podcast.title }}
          <i class="fas fa-info-circle info-icon" (click)="openPodcastInfo(podcast)"></i>
        </h1>
      </div>
      <h2>Episodes</h2>
      <ul>
        <li *ngFor="let episode of episodes" (click)="playEpisode(episode)" (keydown.enter)="playEpisode(episode)" tabindex="0">
          <div class="episode-header">
            <h3>{{ episode.title }}</h3>
            <i class="fas fa-play-circle play-icon"></i>
          </div>
          <p>{{ episode.description }}</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="back-arrow" (click)="goBack()">
    <i class="fas fa-arrow-left"></i> Back
  </div>
  
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    *ngIf="loading$ | async"
  ></ngx-spinner>
  
  <div *ngIf="error$ | async as error" class="error-message">{{ error }}</div>
</div>

<!-- Modal for displaying podcast metadata -->
<div class="modal" [ngClass]="{'visible': modalData}" *ngIf="modalData">
  <div class="modal-content">
    <span class="close" (click)="closeModal()">&times;</span>
    <h2>{{ modalData.title }}</h2>
    <p>{{ modalData.description }}</p>
    <div *ngIf="modalData.podcastMetadata">
      <p><strong>Producer:</strong> {{ modalData.podcastMetadata.producer }}</p>
      <p><strong>Release Date:</strong> {{ modalData.podcastMetadata.releaseDate | date }}</p>
      <p><strong>Duration:</strong> {{ modalData.podcastMetadata.duration }} Minutes</p>
      <p><strong>Rating:</strong> {{ modalData.podcastMetadata.rating }}</p>
      <p><strong>Genre:</strong> {{ modalData.podcastMetadata.genre }}</p>
    </div>
    <div *ngIf="modalData.podcastEpisodeMetadata">
      <p><strong>Author:</strong> {{ modalData.podcastEpisodeMetadata.author }}</p>
      <p><strong>Release Date:</strong> {{ modalData.podcastEpisodeMetadata.releaseDate | date }}</p>
      <p><strong>Duration:</strong> {{ modalData.podcastEpisodeMetadata.duration }} Minutes</p>
      <p><strong>Genre:</strong> {{ modalData.podcastEpisodeMetadata.genre }}</p>
    </div>
  </div>
</div>