<div class="lifestyle-content" [class.no-content]="!loading && lifestyleContent.length === 0">
  <div class="header-container">
    <button class="back-button" (click)="navigateBack()">&#8592; Back</button>
    <h1>Lifestyle Content</h1>
  </div>

  <div *ngIf="loading" class="loading-spinner">
    <p>Loading...</p>
  </div>

  <div *ngIf="!loading && lifestyleContent.length === 0" class="no-content">
    <p>No lifestyle content available at the moment. Please check back later.</p>
  </div>

  <div *ngIf="!loading && lifestyleContent.length > 0" class="content-container">
    <div *ngFor="let item of lifestyleContent" class="content-item" (click)="openVideoPlayer(item)">
      <img [src]="item.image" alt="{{ item.title }}" />
      <h2>{{ item.title }}</h2>
    </div>
  </div>
</div>