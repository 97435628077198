<div class="mat-dialog-container">
  <button class="close-button" (click)="closeModal()">&times;</button>
  <h2 mat-dialog-title class="modal-title">Select Subscription Plan</h2>

  <div mat-dialog-content *ngIf="!isLoading && !error; else loadingOrErrorTemplate">
    <p class="plan-description" *ngIf="plans.length > 0">Choose a plan to unlock premium content and enjoy the full experience</p>
    <p class="description" *ngIf="plans.length === 0">No subscription plans are available at the moment.</p>
    <div class="plans-container" *ngIf="plans.length > 0">
      <div 
        *ngFor="let plan of plans" 
        class="plan-card" 
        [class.selected]="plan === selectedPlan" 
        (click)="selectPlan(plan)"
        (keydown.space)="selectPlan(plan)">
        <h3 class="plan-name">{{ plan.plan }}</h3>
        <p class="plan-description">{{ plan.description }}</p>
        <p class="plan-price">
          {{ plan.currency }}<ng-container>&nbsp;</ng-container>{{ plan.price }}
        </p>
        <p class="plan-duration">{{ plan.duration }}</p>
      </div>
    </div>
  </div>

  <ng-template #loadingOrErrorTemplate>
    <div *ngIf="isLoading" class="loading">
      <mat-spinner></mat-spinner>
      <p>Loading plans...</p>
    </div>
    <div *ngIf="error" class="error">
      <p>{{ error }}</p>
    </div>
  </ng-template>

  <div mat-dialog-actions class="modal-actions">
    <button mat-raised-button color="primary" (click)="confirmSelection()" [disabled]="plans.length === 0 || isLoading || error || hasActiveSubscription">
      Confirm Selection
    </button>
  </div>
</div>