<div class="movie-detail-container" *ngIf="movie$ | async as movie">
  <div class="movie-video">
    <app-video-player [media]="movie"></app-video-player>
  </div>
  <div class="movie-info">
    <div class="movie-header">
      <h1 class="movie-title">{{ movie.title }}</h1>
    </div>
    <p class="movie-description">{{ movie.description }}</p>
    <div class="movie-metadata" *ngIf="movie.movieMetadata">
      <p><strong>Director:</strong> {{ movie.movieMetadata.director }}</p>
      <p><strong>Release Date:</strong> {{ movie.movieMetadata.releaseDate | date }}</p>
      <p><strong>Genre:</strong> {{ movie.movieMetadata.genre }}</p>
      <p><strong>Rating:</strong> {{ movie.movieMetadata.rating }}</p>
      <p><strong>Duration:</strong> {{ movie.movieMetadata.duration }} Minutes</p>
    </div>
  </div>
  <div class="back-arrow" (click)="goBack()">
    <i class="fas fa-arrow-left"></i> Back
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  *ngIf="loading$ | async"
></ngx-spinner>

<div *ngIf="error$ | async as error" class="error">{{ error }}</div>