import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { SubscriptionService } from '../services/subscription.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { loadSubscriptions } from '../store/actions/subscription.actions';
import { AuthenticationService } from '../services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/interfaces/user';
import { ProfileTypeService } from '../services/profile-type.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { PaymentPlanService } from '../services/payment-plan.service';
import { SubscriptionModalComponent } from '../components/subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userInfo: User | null = null;
  subscriptionInfo: any = null;
  plan = '';
  daysToExpiry = '0';
  subscriptionIsActiveStatus: boolean = false;
  confirmDeleteText: string = '';
  isLoading = true;
  hasSubscriptions: boolean = true;
  selectedProfileType: 'child' | 'adult' = 'adult';

  constructor(
    private readonly profileService: ProfileService,
    private readonly subscriptionService: SubscriptionService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly authenticationService: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly profileTypeService: ProfileTypeService,
    private readonly dialog: MatDialog,
    private readonly paymentPlanService: PaymentPlanService
  ) { }
  
  async ngOnInit() {
    this.spinner.show();
    this.userInfo = await this.profileService.getUserInfo();
    if (this.userInfo) {
      const storedProfileType = sessionStorage.getItem('profileType') as 'child' | 'adult';
      if (storedProfileType) {
        this.selectedProfileType = storedProfileType;
      } else {
        this.selectedProfileType = 'adult';
        sessionStorage.setItem('profileType', this.selectedProfileType);
      }
      this.profileTypeService.setProfileType(this.selectedProfileType);
      await this.fetchSubscriptionDetails();
    } else {
      this.openSnackBar('Failed to load user profile', false);
    }
    this.isLoading = false;
    this.spinner.hide();
  }

  isChildProfile(): boolean {
    return this.selectedProfileType === 'child';
  }

  isAdultProfile(): boolean {
    return this.selectedProfileType === 'adult';
  }

  switchProfile(event: MatSlideToggleChange) {
    const profileType = event.checked ? 'adult' : 'child';
    this.selectedProfileType = profileType;
    this.profileTypeService.setProfileType(profileType);
    sessionStorage.setItem('profileType', profileType);
    this.fetchSubscriptionDetails();
  }

  openSnackBar(message: string, isOkay: boolean) {
    const snackbarClass = isOkay ? 'snackbar--success' : 'snackbar--error';
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: [snackbarClass],
    });
  }

  goBack() {
    this.router.navigate(['/content']);
  }

  goToSubscriptionPage() {
    this.router.navigate(['/subscription-details']);
  }

  async fetchSubscriptionDetails() {
    const userId = this.userInfo?.id;
    if (!userId) return;

    try {
      const response$ = this.subscriptionService.getSubscriptions(userId);
      const response = await firstValueFrom(response$);

      if (response?.data?.length > 0) {
        this.subscriptionInfo = response.data[response.data.length - 1];
        this.subscriptionIsActiveStatus = this.subscriptionInfo.activeStatus;

        if (this.isSubscriptionExpired()) {
          this.resetSubscriptionDetails();
          this.openSnackBar('No active subscriptions found.', false);
        } else {
          this.updateSubscriptionDetails();
        }
      } else {
        this.resetSubscriptionDetails();
        this.openSnackBar('No subscriptions found.', false);
      }
    } catch (error) {
      console.error('Error fetching subscription details:', error);
      this.resetSubscriptionDetails();
      this.openSnackBar('Failed to load subscription details', false);
    }
  }

  resetSubscriptionDetails() {
    this.hasSubscriptions = false;
    this.subscriptionIsActiveStatus = false;
    this.plan = 'N/A';
    this.daysToExpiry = 'N/A';
  }

  updateSubscriptionDetails() {
    const today = moment();
    const someday = moment(this.subscriptionInfo.endDate);
    const duration = moment.duration(someday.diff(today));

    this.daysToExpiry = this.formatDuration(duration);
    this.plan = this.subscriptionInfo.plan.duration;
    this.hasSubscriptions = true;
  }

  formatDuration(duration: moment.Duration): string {
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();

    if (years > 0) return `${years} Year${years > 1 ? 's' : ''}`;
    if (months > 0) return `${months} Month${months > 1 ? 's' : ''}`;
    if (days > 0) return `${days} Day${days > 1 ? 's' : ''}`;
    if (hours > 0) return `${hours} Hour${hours > 1 ? 's' : ''}`;
    return '0 Hours';
  }

  async cancelSubscription() {
    const subscriptionId = this.subscriptionInfo?.id;
    if (!subscriptionId) return;

    try {
      this.spinner.show();
      const response$ = this.subscriptionService.cancelSubscription(subscriptionId);
      const response = await firstValueFrom(response$);

      if (response.status === 'success') {
        this.subscriptionIsActiveStatus = false;
        this.openSnackBar('Subscription cancelled successfully!', true);
        this.store.dispatch(loadSubscriptions({ userId: this.userInfo?.id ?? '' }));
        // this.router.navigate(['/content']);
      } else {
        this.openSnackBar('Failed to cancel subscription!', false);
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      this.openSnackBar('Failed to cancel subscription:', false);
    } finally {
      this.spinner.hide();
    }
  }

  async activateSubscription() {
    const subscriptionId = this.subscriptionInfo?.id;
    if (!subscriptionId || this.isSubscriptionExpired()) {
      this.openSnackBar('Cannot activate an expired subscription!', false);
      return;
    }

    try {
      this.spinner.show();
      const response$ = this.subscriptionService.activateSubscription(subscriptionId);
      const response = await firstValueFrom(response$);

      if (response.status === 'success') {
        this.subscriptionIsActiveStatus = true;
        this.openSnackBar('Subscription activated successfully!', true);
        this.store.dispatch(loadSubscriptions({ userId: this.userInfo?.id ?? '' }));
        await this.fetchSubscriptionDetails();
      } else {
        this.openSnackBar('Failed to activate subscription!', false);
      }
    } catch (error) {
      console.error('Error activating subscription:', error);
      this.openSnackBar('Failed to activate subscription:', false);
    } finally {
      this.spinner.hide();
    }
  }

  isSubscriptionExpired(): boolean {
    const today = moment();
    const endDate = moment(this.subscriptionInfo?.endDate);
    return today.isAfter(endDate);
  }

  isSubscriptionCancelledAndNotExpired(): boolean {
    return !this.subscriptionIsActiveStatus && !this.isSubscriptionExpired();
  }

  async deleteAccount() {
    if (this.confirmDeleteText !== 'DELETE') {
      this.openSnackBar('Please type DELETE to confirm account deletion.', false);
      return;
    }

    try {
      this.spinner.show();
      const response$ = this.authenticationService.deleteUserAccount('CONFIRM_DELETE');
      const response = await firstValueFrom(response$);

      if (response.status === 'success') {
        this.openSnackBar('Account deleted successfully! It will be permanently deleted after 30 days.', true);
        this.router.navigate(['/signup']);
      } else {
        this.openSnackBar('Failed to delete account!', false);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      this.openSnackBar('Failed to delete account:', false);
    } finally {
      this.spinner.hide();
    }
  }

  async getSubscriptionByIdAndNavigate(subscriptionId: string) {
    try {
      this.spinner.show();
      const response$ = this.subscriptionService.getSubscription(subscriptionId);
      const response = await firstValueFrom(response$);

      if (response) {
        this.subscriptionInfo = response;
        this.router.navigate(['/subscription-details', subscriptionId]);
      } else {
        this.openSnackBar('Subscription not found.', false);
      }
    } catch (error) {
      console.error('Error fetching subscription by ID:', error);
      this.openSnackBar('Failed to load subscription details', false);
    } finally {
      this.spinner.hide();
    }
  }

  async openSubscriptionModal(): Promise<void> {
    if (this.subscriptionIsActiveStatus) {
      this.openSnackBar('You must cancel your current subscription before selecting a new plan.', false);
      return;
    }

    try {
      this.spinner.show();
      const response$ = this.paymentPlanService.getAllPlans();
      const response = await firstValueFrom(response$);
      console.log('response', response);

      const dialogRef = this.dialog.open(SubscriptionModalComponent, {
        width: '800px',
        height: 'auto',
        maxHeight: '90vh',
        maxWidth: '90vw',
        panelClass: 'custom-dialog-container',
        data: { plans: response },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'subscribed') {
          this.fetchSubscriptionDetails();
        }
      });
    } catch (error) {
      console.error('Error fetching available plans:', error);
      this.openSnackBar('Failed to load available plans', false);
    } finally {
      this.spinner.hide();
    }
  }
}